import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout pageTitle="Page Not Found">
    <div style={{textAlign: 'center', minHeight: `calc(100vh - 118px)`}}>
      <div style={{maxWidth: 800, margin: 'auto', padding: `40px 16px 16px`}}>
        <h3>PAGE NOT FOUND</h3>
        <p>Uh oh, this page has been removed or does not exist!</p>
        <a href="/">GO BACK TO HOME PAGE</a>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
